import React from 'react';
import Body from '@sections/Solutions/Fitness';
import Layout from '@layouts/business';
import SEO from '@components/seo';

export default () => (
  <Layout>
    <SEO
      title="Музыка для фитнес-центров | IMstream"
      description="Музыка для фитнес-центров от IMstream, идеально подходящая для тренировок. Полностью лицензионный музыкальный каталог."
    />
    <Body />
  </Layout>
);
