import { Fitness1, Fitness2, Fitness3 } from '../images';

const data = [
  {
    src: 'https://storage.yandexcloud.net/imstream/fitness_1.wav',
    Image: Fitness1,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/fitness_2.wav',
    Image: Fitness2,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/fitness_3.wav',
    Image: Fitness3,
  },
];

export default data;
