import React, { useRef } from 'react';
import MediaQuery from 'react-responsive';
import Breadcrumb from '../../common/Breadcrumb';
import { Paragraph, Title, Wrapper, Container } from '../../common/Intro';
import Image from './Image';

import useAdjustHeight from '@utils/hooks/adjustHeight';

import data from './data.json';
import styles from './styles.module.css';

export default function() {
  const ref = useRef();
  const height = useAdjustHeight(ref);

  return (
    <Container>
      <div className={styles.inner}>
        <Breadcrumb />
        <Title>{data.title}</Title>
        <Wrapper>
          <div ref={ref}>
            {data.paragraphs.map((text, index) => (
              <Paragraph key={index}>{text}</Paragraph>
            ))}
          </div>
          <MediaQuery minWidth={1024}>
            <Image className={styles.image} style={{ height }} />
          </MediaQuery>
        </Wrapper>
      </div>
    </Container>
  );
}
