import React from 'react';
import styles from './styles.module.css';

export default function({ children, ...rest }) {
  return (
    <h1 {...rest} className={styles.title}>
      {children}
    </h1>
  );
}
